import { ButtonProps } from '@mui/material'

export type IframeEventData =
  | {
      src: 'activity'
      type: 'token'
      payload: 'new' | 'refresh'
    }
  | {
      src: 'fcrostov'
      type: 'token'
      payload: string
    }
  | {
      src: 'fcrostov'
      type: 'url'
      payload: string
    }
  | {
      src: 'activity'
      type: 'token_success'
      payload: string
    }

export enum ApiAnswerStatus {
  ERROR = -1,
  BREAK = 0,
  SUCCESS = 1,
  UNAUTHENTICATED = -401,
  NEED_FULL_REGISTER = -402,
  SERVER_ERROR = 500,
}

export interface ApiAnswer<T> {
  status: ApiAnswerStatus
  message: Message
  data?: T
}

export interface FormProps<T> {
  data: T
  processed: boolean
  snackbar?: SnackbarProps
}

export type Prize = {
  id?: number | null
  slug?: string | null
  name?: string | null
  created_at?: string | null
  price?: number | null
  ball?: number | null
  primary_media?: {
    is_image?: boolean
    thumb_src_link?: string | null
    small_src_link?: string | null
    middle_src_link?: string | null
    src_link?: string | null
  } | null
  access?: boolean | null
}

export type Receipt = {
  id?: number | null
  nalog_status_id?: number | null
  check_status_id?: number | null
  moderate_reason_id?: number | null
  check_fd?: string | null
  check_fn?: string | null
  check_fpd?: string | null
  check_summa?: number | null
  check_date?: string | null
  check_time?: string | null
  shop_inn?: number | null
  shop_name?: string | null
  shop_address?: string | null
  created_at?: string | null
  primary_media?: {
    is_image?: boolean
    thumb_src_link?: string | null
    small_src_link?: string | null
    middle_src_link?: string | null
    src_link?: string | null
  } | null
  ball?: number | null
  nalog_status?: { id?: number | null; slug?: 'wait'; name?: 'Ожидает проверки' } | null
  check_status?: ReceiptStatus | null
  moderate_reason?: { id: number; slug: string; name: string } | null
}

export type ReceiptStatus = {
  id: number
  slug: 'wait' | 'confirm' | 'cancel' | 'moderate' | 'failed' | 'wait_answer' | 'wait_moderate'
  name: string
}

export type UserPrize = {
  id?: number | null
  ball?: number | null
  price?: number | null
  note?: null
  created_at?: string | null
  code?: string | null
  priz?: {
    id?: number | null
    name: string | null
    slug: string | null
    price?: number | null
    primary_media?: {
      is_image?: boolean
      thumb_src_link?: string | null
      small_src_link?: string | null
      middle_src_link?: string | null
      src_link?: string | null
    }
  } | null
  priz_status?: {
    id?: number | null
    slug?: 'confirm' | 'wait' | 'deleted'
    name?: string | null
  }
}

export interface User {
  id?: number | null
  external_id?: number | null
  first_name?: string | null
  middle_name?: string | null
  last_name?: string | null
  email?: string | null
  birthday_at?: string | null
  registered_at?: string | null
  created_at?: string | null
  balance_total?: number | null
  balance_used?: number | null
  balance_ostatok?: number | null
  user_checks?: Receipt[] | null
  user_prizes?: UserPrize[] | null
}

export type messageVariants = 'success' | 'warning' | 'error' | 'info' | 'reset'
export type Message =
  | {
      [key: string]: string[]
    }
  | { [variant in messageVariants]?: string[] }

export type AlertAction = {
  title: string
  onAction?(): void
} & ButtonProps

export type AlertProps = {
  message?: Message
  rootModal?: string
  onClose?(): void
  closeButton?: boolean
  title?: string
  fullScreen?: boolean
  actions?: AlertAction[]
}

export type SnackbarProps = {
  message?: Message
  onClose?(): void
}

export type InputType =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week'
