import thunk from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware } from 'redux'

import { authReducer as auth } from './auth'
import { propsReducer as props } from './props'

const reducers = combineReducers({ auth, props })
const store = createStore(reducers, applyMiddleware(thunk))

export type RootState = ReturnType<typeof reducers>
export type AppDispatch = typeof store.dispatch
export default store
