import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(({ breakpoints: { down } }: Theme) => ({
  root: {
    fontFamily: 'Exo2',
  },
  info: {
    textAlign: 'center',
    padding: 5,
    color: '#889AC1',
    background: '#F4F7FB',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  logo: {
    width: 80,
    cursor: 'pointer',
    [down('md')]: {
      width: 70,
    },
    [down('sm')]: {
      width: 50,
    },
  },
  slogo: {
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontWeight: 600,
    paddingTop: 10,
    fontSize: 22,
    color: '#1C3C87',
    [down('md')]: {
      fontSize: 20,
    },
    [down('sm')]: {
      fontSize: 14,
    },
  },
  slogo2: {
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 18,
    paddingBottom: 10,
    color: '#A0A0A0',
    [down('md')]: {
      fontSize: 16,
    },
    [down('sm')]: {
      fontSize: 12,
    },
  },
  menu: {
    background: '#1C3C87',
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: 16,
    padding: 10,
    fontWeight: 500,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
}))

export default useStyles
