import { AlertProps } from 'types'

export const SET_PROPS = 'SET_PROPS'
export const SET_MODAL = 'SET_MODAL'
export const SET_ALERT = 'SET_ALERT'

export interface ProjectAction {
  userAccepted?: boolean

  [other: string]: unknown
}

export interface ModalAction {
  modal: { [name: string]: { show: boolean; params?: unknown } }
}

export interface AlertAction {
  alert: AlertProps
}

export type PROPS = { type: typeof SET_PROPS; payload: ProjectAction }
export type MODAL = { type: typeof SET_MODAL; payload: ModalAction }
export type ALERT = { type: typeof SET_ALERT; payload: AlertAction }
