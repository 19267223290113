import React, { Fragment, lazy } from 'react'
// import { Intersect } from 'components'
import { User } from 'types'

const KvSection = lazy(() => import('./sections/kv'))
const RulesSection = lazy(() => import('./sections/rules'))
const RegReceiptSection = lazy(() => import('./sections/reg-receipt'))
const ReceiptListSection = lazy(() => import('./sections/receipt-list'))
const PrizeMarketSection = lazy(() => import('./sections/prizes-market'))
const PrizeListSection = lazy(() => import('./sections/prize-list'))

export const MainPage: React.FC<{ user: User; fetchUser(): void }> = ({ user, fetchUser }) => (
  <Fragment>
    <KvSection />

    <RulesSection />

    <RegReceiptSection fetchUser={fetchUser} />

    <ReceiptListSection items={user?.user_checks ?? []} />

    {/* <Intersect> */}
    <PrizeMarketSection balls={user?.balance_ostatok ?? 0} />
    {/* </Intersect> */}

    <PrizeListSection items={user?.user_prizes ?? []} />
  </Fragment>
)
