import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Box, CircularProgress, Fade, Grid } from '@mui/material'
import { useReduxSelector } from 'hooks'
import { ApiAnswerStatus, IframeEventData } from 'types'
import { propsAction } from 'store/props/actions'
import { authAction } from 'store/auth/actions'
import { userInfo } from 'service'
import { MainPage } from 'pages'
import { useDispatch } from 'react-redux'
import ReactGA from 'react-ga'
import { createBrowserHistory as createHistory } from 'history'
import { Header } from '../components/header'
import '../sass/main.sass'
import { useCookies } from 'react-cookie'
// import TagManager from 'react-gtm-module'
// import { Gtm } from 'utils'

const ModalRoot = lazy(() => import('./modal-root'))
const AgreementPage = lazy(() => import('../pages/agreement-page'))

const history = createHistory()
ReactGA.initialize('UA-172980992-5')
ReactGA.pageview(window.location.pathname + window.location.search)
history.listen((location: any) => {
  ReactGA.pageview(location.pathname + location.search)
})

export const sendTopMessage = (message: IframeEventData) => {
  if (message) {
    console.log(message)

    const payload = JSON.stringify(message)
    // web
    window.top?.postMessage(payload, '*')

    // app
    if (typeof window?.ReactNativeWebView !== 'undefined') {
      window.ReactNativeWebView?.postMessage(payload)
    } else {
      setTimeout(() => {
        window.ReactNativeWebView?.postMessage(payload)
      }, 1000)
    }
  }
}

const App: React.FC = () => {
  const dispatch = useDispatch()
  const { user } = useReduxSelector((state) => state.auth)
  const { userAccepted } = useReduxSelector((state) => state.props)
  const [processed, setProcessed] = useState(false)
  const [desktop, setDesktop] = useState(false)
  const [message, setMessage] = useState<string[]>([])
  const [postMessage, setPostMessage] = useState<IframeEventData>()
  const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'main_url'])

  const fetchUser = async () => {
    try {
      setProcessed(true)
      addMessage('Получение данных с токеном: ...' + cookies.access_token?.substr(cookies.access_token.length - 30))

      const { status, data, message } = await userInfo()

      addMessage(`Ответ от сервера: status: ${status}, message: ${message && Object.entries(message)?.[0]?.join(', ')}`)

      if (status === ApiAnswerStatus.SUCCESS && data) {
        dispatch(authAction.setUser(data))
        setProcessed(false)
        dispatch(propsAction.setProps({ userAccepted: Boolean(data?.registered_at) }))
      } else {
        if (cookies.main_url) removeCookie('main_url')
        addMessage('Получение нового токена...')
        sendTopMessage({ src: 'activity', type: 'token', payload: 'new' })
      }
    } catch (e: any) {
      addMessage('Ответ от сервера Catch: ' + e?.message)
      // addMessage('Обновление токена...')
      // sendTopMessage({ src: 'activity', type: 'token', payload: 'new' })
    }
  }

  const parentMessageHandler = (e: MessageEvent) => {
    if (typeof e?.data === 'string') {
      setPostMessage(JSON.parse(e.data) as IframeEventData)
    }
  }

  const addMessage = (v: string) => {
    console.log(v)
    setMessage((p) => [...p, v])
  }

  useEffect(() => {
    if (cookies.main_url && cookies.access_token) setDesktop(true)
    window.addEventListener('message', parentMessageHandler, false)

    return () => {
      window.removeEventListener('message', parentMessageHandler)
    }
  }, [])

  useEffect(() => {
    addMessage('Токен изменен...')

    if (cookies.access_token) {
      addMessage('Токен получен... ' + cookies.access_token.substr(cookies.access_token.length - 30))
    }
    fetchUser()

    // const t2 = setTimeout(() => {
    //   if (!cookies.access_token) {
    //     setProcessed(false)
    //     document.location = 'https://fc-rostov.ru/'
    //   }
    // }, 10000)

    return () => {
      // clearTimeout(t)
      // clearTimeout(t2)
    }
  }, [cookies.access_token])

  useEffect(() => {
    if (postMessage) {
      const { src, type, payload } = postMessage

      if (src === 'fcrostov') {
        const date = new Date()
        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000)

        switch (type) {
          case 'token':
            setCookie('access_token', payload, { expires: date, secure: false })
            break
          case 'url':
            setCookie('main_url', payload, { expires: date })
            setDesktop(true)
            break
        }
      }
    }
  }, [postMessage])

  useEffect(() => {
    if (user && user.id) {
      addMessage('Участник получен...')
      sendTopMessage({ src: 'activity', type: 'token_success', payload: '' })
      // TagManager.initialize({ gtmId: process.env.REACT_APP_GTM ?? '', dataLayerName: 'GAEvent' })
    }
  }, [user.id])

  switch (true) {
    case !user || !user.id || processed:
      return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
          <Grid item>
            <Box textAlign="center">
              <CircularProgress color="secondary" />
              <Box textAlign="center" pb={3} pt={3}>
                Загрузка
              </Box>

              {/* {message.map((i, k) => (
                <Box key={i + k} textAlign="center">
                  <i>{i}</i>
                </Box>
              ))} */}
            </Box>
          </Grid>
        </Grid>
      )

    case user.id && !userAccepted && !processed:
      return (
        <Fade in>
          <Box>
            <Suspense fallback="">
              <AgreementPage fetchUser={fetchUser} />
            </Suspense>
          </Box>
        </Fade>
      )

    case userAccepted:
      return (
        <Fade in>
          <Box pb={10}>
            {/* <Gtm /> */}

            <Suspense fallback="">
              <ModalRoot fetchUser={fetchUser} />
              {desktop && <Header />}
              <MainPage user={user} fetchUser={fetchUser} />

              <ul style={{ marginTop: '20px' }}>
                {message.map((i, k) => (
                  <li key={i + k}>
                    <i>{i}</i>
                  </li>
                ))}
              </ul>
            </Suspense>
          </Box>
        </Fade>
      )

    default:
      return <></>
  }
}

export default App
